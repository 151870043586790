import store from "@/core/services/store";
import baseModuleInstructionType, {
    BASE_URL,
    ITEMS_ALL,
    MODULE_NAME as INSTRUCTION_TYPE_MODULE_NAME,
    SET_ITEMS_ALL,
    GET_ITEMS_ALL
} from "@/core/services/store/instruction-type/instructionType.module";

const _INSTRUCTION_TYPE_MODULE_NAME = INSTRUCTION_TYPE_MODULE_NAME;

export default {
    beforeCreate() {
        function registerStoreModule(moduleName, storeModule) {
            if (!(store && store.state && store.state[moduleName])) {
                store.registerModule(moduleName, storeModule)
            }
        }

        registerStoreModule(_INSTRUCTION_TYPE_MODULE_NAME, baseModuleInstructionType)
    },
    data() {
        return {};
    },
    computed: {
        instructionTypeAllItems() {
            return store.getters[_INSTRUCTION_TYPE_MODULE_NAME + '/' + ITEMS_ALL];
        },
    },
    methods: {
        getInstructionTypeAllItems(){
            this.$store.dispatch(_INSTRUCTION_TYPE_MODULE_NAME + '/' + GET_ITEMS_ALL, {
                filters: {},
                url: BASE_URL
            })
        },
    },
    mounted() {
        if (!this.instructionTypeAllItems || this.instructionTypeAllItems.length  < 0 ) {
            this.$store.commit(_INSTRUCTION_TYPE_MODULE_NAME + '/' + SET_ITEMS_ALL, null);
            this.getInstructionTypeAllItems();
        }
    }
};
